import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddSurvey from "./useAddSurvey";
import Select from "react-select";
import { FieldArray } from "formik";
import Style from "./addSurvey.module.scss";

const AddSurvey = ({ isStickyFooter, refetch }) => {
  const {
    formik,
    isEdit,
    isSurveyCreate,
    getFieldError,
    closeModal,
    basicData,
    addAnswer,
    removeAnswer,
    isAddAnswerDisabled,
  } = useAddSurvey(refetch);

  return (
    <div className="row">
      <div className="">
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Star
          </label>
          <Select
            id="star"
            name="star"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("star") && " error"}`}
            classNamePrefix="pro-input"
            options={basicData?.data?.star_list ?? []}
            value={formik?.values?.star || null}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            onBlur={formik.handleBlur("star")}
            onChange={(option) => formik.setFieldValue("star", option)}
            menuPlacement="auto"
          />
          {getFieldError("star") && (
            <span className="error-text">{getFieldError("star")}</span>
          )}
        </div>
        <div>
            <div className={`${Style.section_item}`}>
              <Input
                label={"Question"}
                type="text"
                id={`question`}
                name={`question`}
                className={`pro-input lg ${
                  formik.touched.question &&
                  formik.errors.question &&
                  " error"
                }`}
                value={formik.values.question}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.question}
                errorMessage={formik.errors.question}
              />
              <Input
                label={"Alias Question"}
                type="text"
                id={`alias_question`}
                name={`alias_question`}
                className={`pro-input lg ${
                  formik.touched.alias_question &&
                  formik.errors.alias_question &&
                  " error"
                }`}
                value={formik.values.alias_question}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.alias_question}
                errorMessage={
                  formik.errors.alias_question
                }
              />
              <div className={`${Style.answer_wrap}`}>
                {formik.values.answers.map((answer, answerIndex) => (
                  <div key={answerIndex} className={`${Style.answer_item}`}>
                    <Input
                      label={"Answer"}
                      type="text"
                      id={`answers[${answerIndex}].answer`}
                      name={`answers[${answerIndex}].answer`}
                      className={`pro-input lg ${
                        formik.touched.answers?.[
                          answerIndex
                        ]?.answer &&
                        formik.errors.answers?.[
                          answerIndex
                        ]?.answer &&
                        " error"
                      }`}
                      value={
                        formik.values.answers[
                          answerIndex
                        ].answer
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.answers?.[
                          answerIndex
                        ]?.answer
                      }
                      errorMessage={
                        formik.errors?.answers?.[
                          answerIndex
                        ]?.answer
                      }
                    />

                    <Input
                      label={"Alias Answer"}
                      type="text"
                      id={`answers[${answerIndex}].alias_answer`}
                      name={`answers[${answerIndex}].alias_answer`}
                      className={`pro-input lg ${
                        formik.touched?.answers?.[
                          answerIndex
                        ]?.alias_answer &&
                        formik.errors.answers?.[
                          answerIndex
                        ]?.alias_answer &&
                        " error"
                      }`}
                      value={
                        formik.values.answers[
                          answerIndex
                        ].alias_answer
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.answers?.[
                          answerIndex
                        ]?.alias_answer
                      }
                      errorMessage={
                        formik.errors.answers?.[
                          answerIndex
                        ]?.alias_answer
                      }
                    />
                    {answerIndex > 0 && (
                      <button
                        className={Style.btn_close}
                        type="button"
                        onClick={() => removeAnswer(answerIndex)}
                      >
                        <span class="material-symbols-outlined x2">close</span>{" "}
                      </button>
                    )}
                  </div>
                ))}

                <div className="pro-d-flex pro-justify-end pro-items-center">
                  <button
                    className={"pro-pnt pro-mt-3"}
                    type="button"
                    onClick={addAnswer}
                    disabled={isAddAnswerDisabled}
                  >
                    Add Answer
                  </button>
                </div>
              </div>
            </div>
        </div>

        <div className="pro-check-box pro-py-2 pro-mt-4">
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={Boolean(formik.values?.status === 1) ?? false}
              onChange={(e) =>
                formik.setFieldValue(`status`, e?.target?.checked ? 1 : 2)
              }
            />
            <label htmlFor="default" className="pro-check-label">
              Status
            </label>
          </div>
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
          disabled={isSurveyCreate}
        >
          {!isEdit
            ? isSurveyCreate
              ? "Creating..."
              : "Create"
            : isSurveyCreate
            ? "Updating..."
            : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default AddSurvey;
