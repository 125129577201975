import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { createSurvey, updateSurvey } from "../api";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/User/Survey/surveySlice";
import { toast } from "react-toastify";
import { useEffect, useMemo, useState } from "react";
import useValidations from "../../../../utils/hooks/useValidations";
import { useGetSurveyBasicDataQuery } from "../../../../store/queries/User";

const useAddSurvey = (refetch) => {
  const dispatch = useDispatch();
  const [isSurveyCreate, setIsSurveyCreate] = useState(false);
  const { arabicOnlyRegex, arabicRegex, englishRegex } = useValidations();
  const { isEdit, editSurveyData } = useSelector((state) => state.survey);
  const { data: basicData = {}, isLoading: basicLoading } =
    useGetSurveyBasicDataQuery();

  // useEffect(() => {
  //   let selectedStar = basicData?.data?.star_list?.find(
  //     (item) => item?.id === editSurveyData?.star
  //   );
  //   const formattedQuestions = editSurveyData?.questions?.map((question) => ({
  //     unique_id: question?.unique_id,
  //     question: question?.question ?? "",
  //     alias_question: question?.alias_question ?? "",
  //     answers: question?.answers?.map((answer) => ({
  //       unique_id: answer?.unique_id,
  //       answer: answer?.answer ?? "",
  //       alias_answer: answer?.alias_answer ?? "",
  //     })),
  //   }));
  //   if (isEdit && editSurveyData !== "") {
  //     formik?.setFieldValue("star", selectedStar ?? "");
  //     formik?.setFieldValue("sections", formattedQuestions ?? []);
  //     formik?.setFieldValue("status", editSurveyData?.status ?? "");
  //   }
  //   //eslint-disable-next-line
  // }, [isEdit, editSurveyData,basicData]);

  useEffect(() => {
    if (isEdit && editSurveyData) {
      let selectedStar = basicData?.data?.star_list?.find(
        (item) => item?.id === editSurveyData?.star
      );
      formik.setValues({
        star: selectedStar ?? "",
        status: editSurveyData?.status ?? "",
        question: editSurveyData?.question ?? "",
        alias_question: editSurveyData?.alias_question ?? "",
        answers: editSurveyData?.answers?.map((answer) => ({
          unique_id: answer?.unique_id,
          answer: answer?.answer ?? "",
          alias_answer: answer?.alias_answer ?? "",
        })) ?? [],
      });
    }
  }, [isEdit, editSurveyData, basicData]);

  const validation = Yup.object().shape({
    star: Yup.object().required("Select star"),
        question: Yup.string()
        .trim()
        .required("Question is required")
        .max(150, "Qustion must be at most 150 characters long")
        .matches(englishRegex, "Enter in english letters"),
    alias_question: Yup.string()
        .trim()
        .required("Alias Question is required")
        .max(150, "Alias Qustion must be at most 150 characters long")
        .matches(arabicRegex, "Enter in arabic letters"),
   answers: Yup.array().of(
          Yup.object().shape({
            answer: Yup.string()
         .required(`Enter your  answer`)
         .max(25, "Answer must be at most 25 characters long")
         .matches(englishRegex, "Enter in english letters"),
   alias_answer: Yup.string()
         .required(`Enter your  answer`)
         .max(25, "Alias Answer must be at most 25 characters long")
         .matches(arabicRegex, "Enter in arabic letters"),
          })
        ),
  });

  const formik = useFormik({
    initialValues: {
      star: "",
      status: 2,
      question: "",
      alias_question: "",
      answers: [
            {
              answer: "",
              alias_answer: "",
            },
          ],
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        setIsSurveyCreate(true);
      const data = isEdit
        ? {
            question_id: editSurveyData?._id,
            star: values.star?.id, 
            status: values.status, 
            question: values.question,
            alias_question: values?.alias_question,
            answers: values?.answers.map((answer) => ({
                unique_id: answer?.unique_id,
                answer: answer?.answer,
                alias_answer: answer?.alias_answer,
              })),
          }
        : {
          star: values.star?.id, 
          status: values.status, 
          question: values.question,
          alias_question: values?.alias_question,
          answers: values?.answers.map((answer) => ({
              answer: answer?.answer,
              alias_answer: answer?.alias_answer,
            })),
          };

        const response = await (isEdit ? updateSurvey(data) : createSurvey(data));
    
        if (response?.data?.status_code === 200) {
          toast.success(
            `Survey ${isEdit ? `Updated` : `Created`} Successfully`
          );
          setIsSurveyCreate(false);
          refetch();
        } else if(response?.data?.status_code === 422){
          toast.error(response?.data?.errors?.star);
        }
      } catch (error) {
        //TODO
        toast.error(error?.message);
        setIsSurveyCreate(false);
      } finally {
        closeModal();
        setIsSurveyCreate(false);
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };

  const addAnswer = () => {
    formik.setValues({
      ...formik.values,
      answers: [...formik.values.answers, { answer: "", alias_answer: "" }],
    });
  };

  const removeAnswer = (answerIndex) => {
    const updatedAnswers = [...formik.values.answers];
    updatedAnswers.splice(answerIndex, 1);
    formik.setValues({ ...formik.values, answers: updatedAnswers });
  };

  const isAddAnswerDisabled = useMemo(() => {
    const lastAnswer = formik.values.answers[formik.values.answers.length - 1];
    return !lastAnswer || !lastAnswer.answer.trim() || !lastAnswer.alias_answer.trim();
  }, [formik.values.answers]);
  
  return {
    formik,
    isEdit,
    isSurveyCreate,
    getFieldError,
    closeModal,
    basicData,
    addAnswer,
    removeAnswer,
    isAddAnswerDisabled,
  };
};

export default useAddSurvey;
